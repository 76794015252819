var WebDataRocksToolbar=function(a,b,d,f,m){this.pivot=b;this.pivotContainer=a;this.width="number"==typeof f||0>f.indexOf("px")&&0>f.indexOf("%")?f+"px":f;this.Labels=m};
WebDataRocksToolbar.prototype.getTabs=function(){var a=[],b=this.Labels;a.push({title:b.connect,id:"wdr-tab-connect",icon:this.icons.connect,menu:[{title:b.connect_local_csv,id:"wdr-tab-connect-local-csv",handler:this.connectLocalCSVHandler,mobile:!1,icon:this.icons.connect_csv},{title:b.connect_local_json,id:"wdr-tab-connect-local-json",handler:this.connectLocalJSONHandler,mobile:!1,icon:this.icons.connect_json},{title:this.osUtils.isMobile?b.connect_remote_csv_mobile:b.connect_remote_csv,id:"wdr-tab-connect-remote-csv",
handler:this.connectRemoteCSV,icon:this.icons.connect_csv},{title:this.osUtils.isMobile?b.connect_remote_json_mobile:b.connect_remote_json,id:"wdr-tab-connect-remote-json",handler:this.connectRemoteJSON,icon:this.icons.connect_json}]});a.push({title:b.open,id:"wdr-tab-open",icon:this.icons.open,menu:[{title:b.local_report,id:"wdr-tab-open-local-report",handler:this.openLocalReport,mobile:!1,icon:this.icons.open_local},{title:this.osUtils.isMobile?b.remote_report_mobile:b.remote_report,id:"wdr-tab-open-remote-report",
handler:this.openRemoteReport,icon:this.icons.open_remote}]});a.push({title:b.save,id:"wdr-tab-save",handler:this.saveHandler,mobile:!1,icon:this.icons.save});a.push({title:b.export,id:"wdr-tab-export",mobile:!1,icon:this.icons.export,menu:[{title:b.export_print,id:"wdr-tab-export-print",handler:this.printHandler,icon:this.icons.export_print},{title:b.export_html,id:"wdr-tab-export-html",handler:this.exportHandler,args:"html",icon:this.icons.export_html},{title:b.export_excel,id:"wdr-tab-export-excel",
handler:this.exportHandler,args:"excel",icon:this.icons.export_excel},{title:b.export_pdf,id:"wdr-tab-export-pdf",handler:this.exportHandler,args:"pdf",icon:this.icons.export_pdf}]});a.push({title:b.format,id:"wdr-tab-format",icon:this.icons.format,rightGroup:!0,menu:[{title:this.osUtils.isMobile?b.format_cells_mobile:b.format_cells,id:"wdr-tab-format-cells",handler:this.formatCellsHandler,icon:this.icons.format_number},{title:this.osUtils.isMobile?b.conditional_formatting_mobile:b.conditional_formatting,
id:"wdr-tab-format-conditional",handler:this.conditionalFormattingHandler,icon:this.icons.format_conditional}]});a.push({title:b.options,id:"wdr-tab-options",handler:this.optionsHandler,icon:this.icons.options,rightGroup:!0});a.push({title:b.fields,id:"wdr-tab-fields",handler:this.fieldsHandler,icon:this.icons.fields,rightGroup:!0});void 0!=document.addEventListener&&a.push({title:b.fullscreen,id:"wdr-tab-fullscreen",handler:this.fullscreenHandler,mobile:!1,icon:this.icons.fullscreen,rightGroup:!0});
return a};WebDataRocksToolbar.prototype.create=function(){this.popupManager=new WebDataRocksToolbar.PopupManager(this);this.dataProvider=this.getTabs();this.init()};
WebDataRocksToolbar.prototype.applyToolbarLayoutClasses=function(){if(!this.osUtils.isMobile){var a=this,b=function(){if(a.toolbarWrapper){var b=a.toolbarWrapper.getBoundingClientRect().width;a.toolbarWrapper.classList.remove("wdr-layout-500");a.toolbarWrapper.classList.remove("wdr-layout-360");a.toolbarWrapper.classList.remove("wdr-layout-300");500>b&&a.toolbarWrapper.classList.add("wdr-layout-500");360>b&&a.toolbarWrapper.classList.add("wdr-layout-360");300>b&&a.toolbarWrapper.classList.add("wdr-layout-300")}};
b();window.addEventListener("resize",b)}};
WebDataRocksToolbar.prototype.init=function(){this.container=this.pivotContainer;this.container.style.position=""==this.container.style.position?"relative":this.container.style.position;this.toolbarWrapper=document.createElement("div");this.toolbarWrapper.id="wdr-toolbar-wrapper";this.toolbarWrapper.style.width=this.width;this.osUtils.isMobile&&this.addClass(this.toolbarWrapper,"wdr-mobile");this.addClass(this.toolbarWrapper,"wdr-toolbar-ui");this.toolbarWrapper.style.width=this.width;var a=document.createElement("ul");
a.id="wdr-toolbar";var b=document.createElement("div");b.classList.add("wdr-toolbar-group-right");a.appendChild(b);for(var d=0;d<this.dataProvider.length;d++)if(!this.isDisabled(this.dataProvider[d]))if(this.osUtils.isMobile&&null!=this.dataProvider[d].menu&&!0!=this.dataProvider[d].collapse)for(var f=0;f<this.dataProvider[d].menu.length;f++)this.isDisabled(this.dataProvider[d].menu[f])||a.appendChild(this.createTab(this.dataProvider[d].menu[f]));else f=this.dataProvider[d].divider?this.createDivider(this.dataProvider[d]):
this.createTab(this.dataProvider[d]),b&&this.dataProvider[d].rightGroup?b.appendChild(f):a.appendChild(f);this.toolbarWrapper.appendChild(a);this.container.insertBefore(this.toolbarWrapper,this.container.firstChild);this.updateLabels(this.Labels);this.applyToolbarLayoutClasses()};
WebDataRocksToolbar.prototype.updateLabels=function(a){a=this.Labels=a;this.setText(document.querySelector("#wdr-tab-connect > a > span"),a.connect);this.setText(document.querySelector("#wdr-tab-connect-local-csv > a > span"),a.connect_local_csv);this.setText(document.querySelector("#wdr-tab-connect-local-json > a > span"),a.connect_local_json);this.setText(document.querySelector("#wdr-tab-connect-remote-csv > a > span"),this.osUtils.isMobile?a.connect_remote_csv_mobile:a.connect_remote_csv);this.setText(document.querySelector("#wdr-tab-open > a > span"),
a.open);this.setText(document.querySelector("#wdr-tab-open-local-report > a > span"),a.local_report);this.setText(document.querySelector("#wdr-tab-open-remote-report > a > span"),this.osUtils.isMobile?a.remote_report_mobile:a.remote_report);this.setText(document.querySelector("#wdr-tab-save > a > span"),a.save);this.setText(document.querySelector("#wdr-tab-format > a > span"),a.format);this.setText(document.querySelector("#wdr-tab-format-cells > a > span"),this.osUtils.isMobile?a.format_cells_mobile:
a.format_cells);this.setText(document.querySelector("#wdr-tab-format-conditional > a > span"),this.osUtils.isMobile?a.conditional_formatting_mobile:a.conditional_formatting);this.setText(document.querySelector("#wdr-tab-options > a > span"),a.options);this.setText(document.querySelector("#wdr-tab-fullscreen > a > span"),a.fullscreen);this.setText(document.querySelector("#wdr-tab-export > a > span"),a.export);this.setText(document.querySelector("#wdr-tab-export-print > a > span"),a.export_print);this.setText(document.querySelector("#wdr-tab-export-html > a > span"),
a.export_html);this.setText(document.querySelector("#wdr-tab-export-excel > a > span"),a.export_excel);this.setText(document.querySelector("#wdr-tab-export-pdf > a > span"),a.export_pdf);this.setText(document.querySelector("#wdr-tab-fields > a > span"),a.fields)};
WebDataRocksToolbar.prototype.icons={connect:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-305.5 396.5 36 36"><title>menu_connect</title><path fill="#555" d="M-274.5 425.409s-.988.277-1.422.761l-3.536-1.783c.189-.509.258-1.066.07-1.572l3.719-1.76a2.36 2.36 0 0 0 3.317.147 2.341 2.341 0 0 0 .157-3.3 2.335 2.335 0 0 0-4.055 1.582c-.009.122-.008.237.001.358l-3.896 1.884a2.281 2.281 0 0 0-1.359-.451 2.338 2.338 0 0 0-2.177 2.481 2.33 2.33 0 0 0 2.177 2.179c.491 0 .967-.156 1.359-.451l3.921 1.892a2.317 2.317 0 0 0 1.981 2.604 2.316 2.316 0 0 0 2.604-1.981c.171-1.269-1-2.432-2.262-2.603a8.794 8.794 0 0 0-.6-.026v.039zM-301.592 413.883c-.008.105-.908.222.092.326v6.836c0 2.587 5.827 5.455 13.177 5.455h1.081c-1.146-3 .303-6.854 3.299-8.155-1.448.172-2.868.069-4.334.069-7.056.009-12.775-2.093-13.315-4.531z"/><path fill="#555" d="M-288.5 416.217c7.377 0 13-2.097 13-4.683v-6.853c0-2.586-5.647-4.682-13-4.682-7.352 0-13 2.104-13 4.69v6.853c0 2.586 5.648 4.675 13 4.675zm.177-15.268c5.903 0 10.691 1.661 10.691 3.741s-4.796 3.741-10.691 3.741c-5.894 0-10.683-1.67-10.683-3.741 0-2.072 4.79-3.741 10.683-3.741z"/></svg>',connect_csv:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M11 24l6 0V27h-6V24z"/><path d="M12.8 22L12.8 22l1.2-2.5L15.1 22h1.9l-2-3.9L16.9 14h-1.8l-1 2.5L12.9 14h-1.8l1.9 3.9L11 22H12.8z"/><path d="M19 19h6v3h-6V19z"/><path d="M19 14h6v3L19 17V14z"/><path d="M19 24h6v3h-6V24z"/><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"/></svg>',
connect_json:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"/><path d="M19 24c0 0.6-0.4 1-1 1 -0.6 0-1-0.4-1-1v-2c0-0.6 0.4-1 1-1 0.6 0 1 0.4 1 1V24zM21 18v-2c0-0.6-0.4 0-1 0 -0.6 0-1-0.4-1-1 0-0.6 0.4-1 1-1 1.7 0 3 0.3 3 2v2c0 1.1 0.9 2 2 2 0.6 0 1 0.4 1 1 0 0.6-0.4 1-1 1 -1.1 0-2 0.9-2 2v2c0 1.7-1.3 2-3 2 -0.6 0-1-0.4-1-1s0.4-1 1-1c0.6 0 1 0.6 1 0v-2c0-1.2 0.5-2.3 1.4-3C21.5 20.3 21 19.2 21 18zM11 20c1.1 0 2-0.9 2-2v-2c0-1.7 1.3-2 3-2 0.6 0 1 0.4 1 1 0 0.6-0.4 1-1 1 -0.6 0-1-0.6-1 0v2c0 1.2-0.5 2.3-1.4 3 0.8 0.7 1.4 1.8 1.4 3v2c0 0.6 0.4 0 1 0 0.6 0 1 0.4 1 1s-0.4 1-1 1c-1.7 0-3-0.3-3-2v-2c0-1.1-0.9-2-2-2 -0.6 0-1-0.4-1-1C10 20.4 10.4 20 11 20z"/><path d="M18 17c0.6 0 1 0.4 1 1s-0.4 1-1 1 -1-0.4-1-1S17.4 17 18 17z"/></svg>',
open:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-305.5 396.5 36 36"><title>menu_open</title><path fill="#555" d="M-279.351 408.5h8.976c.064 0 .126-.067.167-.025.021.052.021.055 0 .107l-2.053 20.701c-.01.117-.104.217-.219.217h-30.102c-.116 0-.21-.104-.221-.22l-1.989-16.009c-.022-.041-.022.124 0 .083.042-.054.115.146.178.146h21.198c.87 0 1.665-.726 2.053-1.499l1.812-3.446a.414.414 0 0 1 .2-.055zm-4.052 2.473c.084-.011.162-.052.194-.126l1.813-3.288c.408-.754 1.196-1.059 2.054-1.059h7.842v-2.637c0-1.102-.83-2.166-1.929-2.25-.053 0-.183-.113-.235-.113h-18.328c-.104 0-.188.006-.241-.09.01-1.1-.858-1.91-1.958-1.91h-6.902c-1.226 0-2.406.864-2.406 2.089v9.426l20.096-.042z"/></svg>',
open_local:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><style>.a{fill:none;}</style><path d="M30.9 10.6C30.8 10.4 30.2 10 30 10h-1V8c0-0.4-0.6-1-1-1H15l-1-2H8C7.6 5 7 5.6 7 6v4H6c-0.2 0-0.8 0.4-0.9 0.6 -0.1 0.1-0.2 0.3-0.1 0.5l2.1 19.5C7.2 30.8 7.7 31 8 31h20c0.3 0 0.8-0.2 0.9-0.5l2.1-19.5C31 10.9 31 10.7 30.9 10.6zM28 30H8L6 11h24L28 30z"/><line x1="11" y1="23" x2="11" y2="23" class="a"/><line x1="25" y1="23" x2="25" y2="23" class="a"/><polygon points="11 15 11 23 17 23 17 25 14 25 14 26 22 26 22 25 19 25 19 23 25 23 25 15 "/></svg>',
open_remote:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M30.9 10.6C30.8 10.4 30.2 10 30 10h-1V8c0-0.4-0.6-1-1-1H15l-1-2H8C7.6 5 7 5.6 7 6v4H6c-0.2 0-0.8 0.4-0.9 0.6 -0.1 0.1-0.2 0.3-0.1 0.5l2.1 19.5C7.2 30.8 7.7 31 8 31h20c0.3 0 0.8-0.2 0.9-0.5l2.1-19.5C31 10.9 31 10.7 30.9 10.6zM28 30H8L6 11h24L28 30z"/><path d="M24.8 18.1l-0.8 1.5c-0.2 0.2-0.5 0.2-0.8 0 -1.3-1.2-3.2-1.9-5.3-1.9 -2.1 0-4 0.7-5.3 1.9 -0.2 0.2-0.5 0.2-0.8 0l-0.8-1.5c-0.1-0.1-0.2-0.2-0.2-0.3 0-0.1 0.1-0.2 0.2-0.3 1.7-1.5 4.1-2.5 6.8-2.5 2.7 0 5.1 0.9 6.8 2.5 0.1 0 0.2 0.2 0.2 0.3C25 17.9 24.9 18 24.8 18.1zM18 19.6c1.5 0 2.8 0.5 3.8 1.4 0.2 0.2 0.2 0.5 0 0.7l-0.8 1.5c-0.2 0.2-0.5 0.2-0.8 0 -0.6-0.5-1.4-0.8-2.3-0.8 -0.9 0-1.7 0.3-2.3 0.8 -0.2 0.2-0.5 0.2-0.8 0l-0.8-1.5c-0.2-0.2-0.2-0.5 0-0.7C15.2 20.1 16.5 19.6 18 19.6zM18 23.4c0.8 0 1.4 0.6 1.4 1.3 0 0.7-0.6 1.3-1.4 1.3 -0.8 0-1.4-0.6-1.4-1.3C16.6 24 17.2 23.4 18 23.4z"/></svg>',
save:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>menu_save</title><g fill="#555"><path d="M15.191 783.284l-5.457-5.467a1.101 1.101 0 0 0-.781-.317h-.835c-.2 0-.618.163-.618.363v8.889c0 .618-.245.748-.853.748H-5.638c-.617 0-.862-.14-.862-.748v-8.889c0-.2-.409-.363-.609-.363h-6.283c-.618 0-1.108.499-1.108 1.107v27.785c0 .617.5 1.107 1.108 1.107h27.784c.618 0 1.108-.499 1.108-1.107v-22.337c0-.29-.109-.562-.309-.771zM8.98 801.463c0 .618-.499 1.037-1.107 1.037H-6.873c-.617 0-.627-.357-.627-.966v-8.626c0-.617.019-1.408.627-1.408H7.882c.618 0 1.108.613 1.108 1.223l-.01 8.74z"/><path d="M-4.167 784.5h9.361c.2 0 .306.228.306.028v-6.665c0-.2-.106-.363-.306-.363h-9.361c-.2 0-.333.163-.333.363v6.665c0 .191.124-.028.333-.028zm5.667-4.294c0-.618.392-1.108 1-1.108.618 0 1 .5 1 1.108v1.97c0 .618-.392 1.108-1 1.108-.618 0-1-.5-1-1.108v-1.97zM4.168 796.5h-7.31c-.617 0-1.108.393-1.108 1s.5 1 1.108 1h7.31c.617 0 1.107-.393 1.107-1s-.498-1-1.107-1z"/></g></svg>',
export:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>menu_export</title><g fill="#555"><path d="M15.446 795.615l-4.289-6.461c-.346-.515-.803-.654-1.428-.654H7.788c-.186 0-.346-.029-.363.156-.008.076.017.07.059.137l4.76 7.108c.042.06.034.337-.017.38-.025.025-.067.219-.102.219H6.699c-.194 0-.354-.063-.363.125-.305 3.23-3.174 5.495-6.407 5.192-2.81-.263-5.039-2.329-5.3-5.14-.009-.195-.168-.178-.363-.178h-5.401c-.076 0-.144-.281-.144-.357 0-.025.008-.157.017-.175l4.76-7.203c.102-.16.05-.245-.109-.347-.06-.035-.118.082-.187.082h-1.94c-.616 0-1.199.145-1.553.658l-4.664 6.547c-.203.304-.545.586-.545.95v9.216c1 .911 1.267 1.646 2.187 1.629h27.625c.903.009 1.188-.709 1.188-1.611v-9.233c1-.373.157-.735-.054-1.04z"/><path d="M-3.674 783.5H-2.5v10.2c1 1.4 1.764 2.464 3.165 2.371 1.274-.083 1.835-1.097 2.835-2.371v-10.2h1.207c.346 0 .641-.04.65-.387.008-.151-.042-.193-.144-.311l-4.186-5.11c-.228-.287-.642-.302-.929-.073-.042.034-.076.081-.101.115l-4.135 5.172c-.22.271-.187.447.084.668.11.085.244-.074.38-.074z"/></g></svg>',
export_print:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>dd_save_print</title><path fill="#555" d="M-5.5 798.685v3.815h11v-7h-11v3.185zm2-1.185h7v1h-7v-1zm0 2h7v1h-7v-1zM5.5 786.308V782.5h-11v7h11z"/><path fill="#555" d="M8.94 786.5H7.5v4h-14v-4h-1.44c-1.493 0-2.56 1.064-2.56 2.558v6.87c0 1.493 1.067 2.572 2.56 2.572h1.44v-4h14v4h1.44c1.493 0 2.56-1.064 2.56-2.557v-6.878c0-1.501-1.074-2.565-2.56-2.565z"/></svg>',export_excel:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M24.4 15h-3.8L18 18.6 15.3 15h-3.8l4.5 5.2L11 27h7.3L18 25h-2l2-3L21.1 27H25l-5.1-6.8L24.4 15z"/><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"/></svg>',
export_html:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M25.7 20.8l-2.3-2.5c-0.2-0.2-0.4-0.3-0.6-0.3 -0.2 0-0.4 0.1-0.6 0.3 -0.3 0.4-0.3 1 0 1.4l1.7 1.9 -1.7 1.9c-0.2 0.2-0.3 0.4-0.3 0.7 0 0.3 0.1 0.5 0.3 0.7 0.2 0.2 0.4 0.3 0.6 0.3 0.2 0 0.4-0.1 0.6-0.3l2.3-2.5C26.1 21.8 26.1 21.2 25.7 20.8z"/><path d="M14 24c0-0.3-0.1-0.5-0.3-0.7l-1.7-1.9 1.7-1.9c0.3-0.4 0.3-1 0-1.4 -0.2-0.2-0.4-0.3-0.6-0.3 -0.2 0-0.4 0.1-0.6 0.3l-2.3 2.5c-0.3 0.4-0.3 1 0 1.4l2.3 2.5c0.2 0.2 0.4 0.3 0.6 0.3 0.2 0 0.4-0.1 0.6-0.3C13.9 24.5 14 24.3 14 24z"/><path d="M20.4 15.1c-0.1 0-0.2-0.1-0.3-0.1 -0.4 0-0.8 0.3-0.9 0.6l-4.1 11.1c-0.1 0.2-0.1 0.5 0 0.7 0.1 0.2 0.3 0.4 0.5 0.5C15.7 28 15.8 28 16 28c0.4 0 0.8-0.3 0.9-0.6l4.1-11.1C21.1 15.8 20.9 15.2 20.4 15.1z"/><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"/></svg>',
export_pdf:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M17.2 17.1L17.2 17.1C17.3 17.1 17.3 17.1 17.2 17.1c0.1-0.5 0.2-0.7 0.2-1V15.8c0.1-0.6 0.1-1 0-1.1 0 0 0 0 0-0.1l-0.1-0.1 0 0 0 0c0 0 0 0.1-0.1 0.1C16.9 15.2 16.9 16 17.2 17.1L17.2 17.1zM13.8 24.8c-0.2 0.1-0.4 0.2-0.6 0.3 -0.8 0.7-1.3 1.5-1.5 1.8l0 0 0 0 0 0C12.5 26.9 13.1 26.2 13.8 24.8 13.9 24.8 13.9 24.8 13.8 24.8 13.9 24.8 13.8 24.8 13.8 24.8zM24.1 23.1c-0.1-0.1-0.6-0.5-2.1-0.5 -0.1 0-0.1 0-0.2 0l0 0c0 0 0 0 0 0.1 0.8 0.3 1.6 0.6 2.1 0.6 0.1 0 0.1 0 0.2 0l0 0h0.1c0 0 0 0 0-0.1l0 0C24.2 23.3 24.1 23.3 24.1 23.1zM24.6 24c-0.2 0.1-0.6 0.2-1 0.2 -0.9 0-2.2-0.2-3.4-0.8 -1.9 0.2-3.4 0.5-4.5 0.9 -0.1 0-0.1 0-0.2 0.1 -1.3 2.4-2.5 3.5-3.4 3.5 -0.2 0-0.3 0-0.4-0.1l-0.6-0.3v-0.1c-0.1-0.2-0.1-0.3-0.1-0.6 0.1-0.6 0.8-1.6 2.1-2.4 0.2-0.1 0.6-0.3 1-0.6 0.3-0.6 0.7-1.2 1.1-2 0.6-1.1 0.9-2.3 1.2-3.3l0 0c-0.4-1.4-0.7-2.1-0.2-3.7 0.1-0.5 0.4-0.9 0.9-0.9h0.2c0.2 0 0.4 0.1 0.7 0.2 0.8 0.8 0.4 2.6 0 4.1 0 0.1 0 0.1 0 0.1 0.4 1.2 1.1 2.3 1.8 2.9 0.3 0.2 0.6 0.5 1 0.7 0.6 0 1-0.1 1.5-0.1 1.3 0 2.2 0.2 2.6 0.8 0.1 0.2 0.1 0.5 0.1 0.7C24.9 23.5 24.8 23.8 24.6 24zM17.3 19.6c-0.2 0.8-0.7 1.7-1.1 2.7 -0.2 0.5-0.4 0.8-0.7 1.2h0.1 0.1l0 0c1.5-0.6 2.8-0.9 3.7-1 -0.2-0.1-0.3-0.2-0.4-0.3C18.4 21.6 17.7 20.7 17.3 19.6z"/><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"/></svg>',
format:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>menu_format</title><g fill="#555"><path d="M15.144 781.92a7.62 7.62 0 0 0-4.238-4.157 4.634 4.634 0 0 0-1.201-.254.99.99 0 0 0-.906.36l-10.797 10.829c-1.462 1.462-2.916 2.908-4.37 4.378a1.347 1.347 0 0 0-.326.596c-.481 2.197-.939 4.402-1.413 6.607a.639.639 0 0 0 .792.793l6.551-1.381c.253-.049.49-.171.67-.359 5.063-5.08 10.127-10.144 15.19-15.207.237-.229.384-.548.4-.882a8.491 8.491 0 0 0-.352-1.323zm-16.825 16.652l-2.54.531a2.367 2.367 0 0 0-1.911-1.87c.18-.906.384-1.813.571-2.729a2.05 2.05 0 0 1 1.078.229 6.007 6.007 0 0 1 2.671 2.605c.139.318.245.653.311.996 0 .157 0 .157-.18.238z"/><path d="M6.568 804.5H-11.5v-18h7.761l1.83-2.118 1.593-1.882h-12.455c-.947 0-1.729 1.241-1.729 2.18v21.097c0 .947.77 1.724 1.726 1.724H8.283c.947 0 2.217-.769 2.217-1.724v-12.488l-4 3.438v7.773h.068z"/></g></svg>',
format_number:'<svg xmlns="http://www.w3.org/2000/svg" width="52" height="36" viewBox="0 0 52 36"><path d="M31 19.2v-3.4l2.5-0.4c0.2-0.7 0.5-1.4 0.9-2.1l-1.4-2 2.4-2.4 2 1.4c0.7-0.4 1.4-0.7 2.1-0.9l0.4-2.5h3.4l0.4 2.5c0.7 0.2 1.4 0.5 2.1 0.9l2-1.4 2.4 2.4 -1.4 2c0.4 0.7 0.7 1.4 0.9 2.1L52 15.8v3.4l-2.5 0.4c-0.2 0.7-0.5 1.4-0.9 2.1l1.4 2 -2.4 2.4 -2-1.5c-0.7 0.4-1.4 0.7-2.1 0.9l-0.4 2.5h-3.4l-0.4-2.5c-0.7-0.2-1.4-0.5-2.1-0.9l-2 1.5 -2.4-2.4 1.4-2c-0.4-0.7-0.7-1.4-0.9-2.1L31 19.2zM41.5 21c1.9 0 3.5-1.6 3.5-3.5 0-1.9-1.6-3.5-3.5-3.5 -1.9 0-3.5 1.6-3.5 3.5C38 19.4 39.6 21 41.5 21z"/><path d="M38 30H1V6h36V5H0v26h38V30L38 30z"/><path d="M9.4 21.1c-0.3 0.3-0.7 0.4-1.2 0.4 -0.5 0-0.9-0.2-1.2-0.5s-0.4-0.8-0.4-1.4H5c0 0.9 0.2 1.7 0.7 2.2 0.5 0.6 1.2 0.9 2 1v1.3h1.1v-1.4c0.8-0.1 1.5-0.4 1.9-0.9 0.5-0.5 0.7-1.1 0.7-1.9 0-0.4-0.1-0.8-0.2-1.1 -0.1-0.3-0.3-0.6-0.5-0.8 -0.2-0.2-0.5-0.4-0.8-0.6 -0.3-0.2-0.8-0.4-1.4-0.6 -0.6-0.2-1-0.4-1.2-0.7s-0.4-0.6-0.4-1c0-0.4 0.1-0.8 0.4-1 0.2-0.2 0.6-0.4 1-0.4 0.4 0 0.8 0.2 1 0.5 0.3 0.3 0.4 0.8 0.4 1.4h1.6c0-0.9-0.2-1.6-0.6-2.2 -0.4-0.6-1-0.9-1.8-1v-1.5H7.9v1.5C7.1 12.6 6.5 12.9 6 13.4s-0.7 1.1-0.7 1.9c0 1.1 0.5 2 1.6 2.6 0.3 0.2 0.8 0.4 1.3 0.6 0.6 0.2 1 0.4 1.2 0.7s0.4 0.6 0.4 1C9.8 20.5 9.7 20.8 9.4 21.1z"/><path d="M16.3 12.6h-0.2l-3.8 1.5v1.4l2.4-0.8v8.1h1.6V12.6z"/><path d="M19.9 23.8c0.2-0.5 0.4-1 0.4-1.5l0-1.2h-1.5v1.3c0 0.3-0.1 0.6-0.2 1 -0.1 0.3-0.3 0.7-0.5 1.1l0.9 0.5C19.3 24.7 19.6 24.3 19.9 23.8z"/><path d="M27 16.7c0-1.4-0.3-2.5-0.8-3.2s-1.3-1.1-2.4-1.1c-1.1 0-1.9 0.4-2.4 1.1 -0.5 0.7-0.8 1.8-0.8 3.3v1.8c0 1.4 0.3 2.5 0.8 3.2s1.3 1.1 2.4 1.1c1.1 0 1.9-0.4 2.4-1.1 0.5-0.7 0.8-1.8 0.8-3.3V16.7zM25.4 18.9c0 0.9-0.1 1.6-0.4 2 -0.2 0.4-0.6 0.6-1.2 0.6 -0.5 0-0.9-0.2-1.2-0.7 -0.3-0.5-0.4-1.2-0.4-2.1v-2.3c0-0.9 0.1-1.5 0.4-2 0.3-0.4 0.6-0.6 1.2-0.6 0.5 0 0.9 0.2 1.2 0.7 0.3 0.4 0.4 1.1 0.4 2.1V18.9z"/></svg>',
format_conditional:'<svg xmlns="http://www.w3.org/2000/svg" width="52" height="36" viewBox="0 0 52 36"><polygon points="38 5 0 5 0 31 26 31 26 30 1 30 1 6 37 6 37 10 38 10 "/><path d="M9 13H8.6L5 14.4v1.4l2-0.8V23h2V13z"/><path d="M17 22h-4l2.4-2.9c0.6-0.7 1-1.3 1.3-1.8 0.3-0.5 0.4-1.1 0.4-1.5 0-0.8-0.3-1.5-0.8-2 -0.5-0.5-1.2-0.7-2.2-0.7 -0.6 0-1.2 0.1-1.7 0.4s-0.9 0.6-1.1 1C11.1 14.9 11 16 11 16h1.6c0 0 0.1-0.9 0.4-1.3s0.7-0.4 1.2-0.4c0.4 0 0.8 0.2 1 0.5 0.3 0.3 0.4 0.7 0.4 1.1 0 0.4-0.1 0.7-0.3 1.1 -0.2 0.4-0.6 0.8-1.1 1.3L11 21.9V23h6V22z"/><path d="M21 19h0.8c0.6 0 1-0.1 1.3 0.2 0.3 0.3 0.4 0.6 0.4 1.1 0 0.5-0.1 0.8-0.4 1.1 -0.3 0.3-0.6 0.4-1.1 0.4 -0.5 0-0.8-0.3-1.1-0.5C20.6 20.9 20.5 21 20.5 20h-1.5c0 1 0.3 1.6 0.8 2.1s1.3 0.8 2.1 0.8c0.9 0 1.6-0.2 2.2-0.7 0.6-0.5 0.8-1.2 0.8-2.1 0-0.5-0.1-1-0.4-1.4 -0.3-0.4-0.6-0.7-1.1-0.9 0.4-0.2 0.7-0.5 1-0.9 0.3-0.4 0.4-0.8 0.4-1.2 0-0.9-0.3-1.5-0.8-2 -0.5-0.5-1.2-0.7-2.1-0.7 -0.5 0-1 0.1-1.5 0.3 -0.4 0.2-0.8 0.7-1 1.1S19.1 15 19.1 16h1.5c0-1 0.1-0.9 0.4-1.1 0.3-0.3 0.6-0.5 1-0.5 0.5 0 0.8 0.1 1 0.3s0.3 0.6 0.3 1.1c0 0.5-0.1 0.7-0.4 1C22.7 17 22.3 17 21.9 17H21V19z"/><path d="M47.8 7C50.1 7 52 8.9 52 11.2c0 1-0.3 1.8-0.8 2.5l-1.7 1.7L43.6 9.5l1.7-1.7C46 7.3 46.8 7 47.8 7zM30.7 22.4L29 30l7.6-1.7 11.6-11.6 -5.9-5.9L30.7 22.4zM42 17.9l-5.1 5.1 -0.9-0.9 5.1-5L42 17.9z"/></svg>',
options:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>menu_options</title><path fill="#555" d="M11.363 789.058l-.76-1.838a33.692 33.692 0 0 0 1.601-4.223l-2.327-2.328a31.444 31.444 0 0 0-4.142 1.691l-1.829-.76a32.88 32.88 0 0 0-1.83-4.101h-3.291a32.106 32.106 0 0 0-1.731 4.133l-1.83.76a33.556 33.556 0 0 0-4.206-1.609l-2.328 2.32a31.563 31.563 0 0 0 1.69 4.141l-.759 1.838a34.366 34.366 0 0 0-4.117 1.838v3.3a32.519 32.519 0 0 0 4.117 1.731l.759 1.829a33.633 33.633 0 0 0-1.608 4.223l2.327 2.328a32.986 32.986 0 0 0 4.133-1.699l1.83.76a31.481 31.481 0 0 0 1.862 4.108h3.291a32.194 32.194 0 0 0 1.732-4.133l1.837-.76a33.57 33.57 0 0 0 4.207 1.609l2.327-2.328a31.481 31.481 0 0 0-1.69-4.141l.76-1.838a32.532 32.532 0 0 0 4.108-1.829v-3.3a30.142 30.142 0 0 0-4.133-1.722zM.5 799.202a6.706 6.706 0 1 1 6.706-6.706A6.71 6.71 0 0 1 .5 799.202z"/></svg>',
fields:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>menu_fields</title><g fill="#555"><path d="M11.351 787.279c1.112.367 2.027 1.221 2.55 2.221H13.5v-10.627c0-.744-.089-1.357-.825-1.373h-26.339c-.736.008-.836.612-.836 1.349v21.656c0 .735.093.995.836.995h10.836c-.008 0 .205-1.314.629-2.025-.073-.098-.123.025-.18.025H-12.5v-6h10.563l.83-1.699c.4-.76 1.606-1.258 1.606-1.667V785.5h10.843l.009 1.779zM-1.5 791.5h-11v-6h11v6z"/><path d="M15.805 801.444l-1.602-1.308c0-.237.082-.49.082-.817a2.483 2.483 0 0 0-.082-.817l1.602-1.389a.399.399 0 0 0 .082-.49l-1.528-2.86a.364.364 0 0 0-.278-.123.678.678 0 0 0-.18 0l-1.912.866a6.95 6.95 0 0 0-1.299-.817l-.311-1.989c-.017-.195-.188-.199-.384-.199H6.93c-.147 0-.384.037-.384.199l-.311 2.053c-.458.229-.899.468-1.299.786l-1.912-.834c-.041-.008-.09-.016-.131-.008a.356.356 0 0 0-.319.192l-1.528 2.817c-.082.164-.082.408.082.489l1.749 1.308c0 .237-.082.489-.082.816a2.48 2.48 0 0 0 .082.817l-1.602 1.356a.399.399 0 0 0-.082.49l1.528 2.86a.364.364 0 0 0 .278.123.678.678 0 0 0 .18 0l1.912-.817c.4.318.842.597 1.299.817l.311 2.116a.322.322 0 0 0 .221.4c.033.008.065.017.09.017h3.065c.147 0 .384-.164.384-.327l.311-2.215c.466-.212.907-.49 1.299-.817l1.831.817a.441.441 0 0 0 .54-.163l1.528-2.86c.056-.08-.018-.326-.165-.489zm-7.306.744a2.865 2.865 0 0 1-3.064-2.664c-.114-1.585 1.079-2.95 2.664-3.064a2.867 2.867 0 0 1 3.072 2.868 2.804 2.804 0 0 1-2.672 2.86z"/></g></svg>',
fullscreen:'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="-17.5 774.5 36 36"><title>menu_fullscreen_open</title><g fill="#555"><path d="M-7.5 779.976V777.5h-7v7h3v-2.752l3.758 4.067 1.74-1.846-4.342-4.003 2.844.01zM-7.742 799.096l-3.758 4.101V800.5h-3v7h7v-2h-2.618l-.049-.371 4.124-4.054-1.699-1.979zM8.746 785.913l3.754-4.092v2.679h3v-7h-7v2h2.602l.082.381-4.126 4.041 1.688 1.991zM13.5 800.5v2.598l-.382.074-4.058-4.003-1.882 1.575 4.006 3.756H8.5v3h7v-7h-2zM-4 786.5h9c1.104 0 2.5.908 2.5 2.011v7.996c0 1.103-1.396 1.993-2.5 1.993h-9c-1.104 0-2.5-.891-2.5-1.993v-8.005c0-1.103 1.396-2.002 2.5-2.002z"/></g></svg>'};
WebDataRocksToolbar.prototype.connectLocalCSVHandler=function(){this.pivot.connectTo({dataSourceType:"csv",browseForFile:!0})};WebDataRocksToolbar.prototype.connectLocalJSONHandler=function(){this.pivot.connectTo({dataSourceType:"json",browseForFile:!0})};WebDataRocksToolbar.prototype.connectRemoteCSV=function(){this.showConnectToRemoteCSVDialog()};WebDataRocksToolbar.prototype.connectRemoteJSON=function(){this.showConnectToRemoteJSONDialog()};WebDataRocksToolbar.prototype.openLocalReport=function(){this.pivot.open()};
WebDataRocksToolbar.prototype.openRemoteReport=function(){this.showOpenRemoteReportDialog()};WebDataRocksToolbar.prototype.saveHandler=function(){this.pivot.save("report.json","file")};WebDataRocksToolbar.prototype.formatCellsHandler=function(){this.showFormatCellsDialog()};WebDataRocksToolbar.prototype.conditionalFormattingHandler=function(){this.showConditionalFormattingDialog()};WebDataRocksToolbar.prototype.optionsHandler=function(){this.showOptionsDialog()};
WebDataRocksToolbar.prototype.fieldsHandler=function(){this.pivot.openFieldsList()};WebDataRocksToolbar.prototype.printHandler=function(){this.pivot.print()};WebDataRocksToolbar.prototype.exportHandler=function(a){"pdf"==a?this.showExportPdfDialog():this.pivot.exportTo(a)};WebDataRocksToolbar.prototype.fullscreenHandler=function(){this.toggleFullscreen()};WebDataRocksToolbar.prototype.defaults={};
WebDataRocksToolbar.prototype.showConnectToRemoteCSVDialog=function(){var a=this,b=this.Labels,d=this.popupManager.createPopup();d.content.classList.add("wdr-popup-w500");d.setTitle(b.open_remote_csv);d.setToolbar([{id:"wdr-btn-open",label:b.open,handler:function(){0<f.value.length&&a.pivot.connectTo({filename:f.value,dataSourceType:"csv"})},isPositive:!0},{id:"wdr-btn-cancel",label:b.cancel}]);var b=document.createElement("div"),f=document.createElement("input");f.id="wdr-inp-file-url";f.type="text";
f.value="https://cdn.webdatarocks.com/data/data.csv";b.appendChild(f);d.setContent(b);this.popupManager.addPopup(d.content)};
WebDataRocksToolbar.prototype.showConnectToRemoteJSONDialog=function(){var a=this,b=this.Labels,d=this.popupManager.createPopup();d.content.classList.add("wdr-popup-w500");d.setTitle(b.open_remote_json);d.setToolbar([{id:"wdr-btn-open",label:b.open,handler:function(){0<f.value.length&&a.pivot.connectTo({filename:f.value,dataSourceType:"json"})},isPositive:!0},{id:"wdr-btn-cancel",label:b.cancel}]);var b=document.createElement("div"),f=document.createElement("input");f.id="wdr-inp-file-url";f.type=
"text";f.value="https://cdn.webdatarocks.com/data/data.json";b.appendChild(f);d.setContent(b);this.popupManager.addPopup(d.content)};
WebDataRocksToolbar.prototype.showOpenRemoteReportDialog=function(){var a=this,b=this.Labels,d=this.popupManager.createPopup();d.content.classList.add("wdr-popup-w500");d.setTitle(b.open_remote_report);d.setToolbar([{id:"wdr-btn-open",label:b.open,handler:function(){0<f.value.length&&a.pivot.load(f.value)},isPositive:!0},{id:"wdr-btn-cancel",label:b.cancel}]);var b=document.createElement("div"),f=document.createElement("input");f.type="text";var m=a.pivot.getOptions()||{};f.value=m.grid&&"flat"==
m.grid.type?"https://cdn.webdatarocks.com/reports/report-flat.json":"https://cdn.webdatarocks.com/reports/report.json";b.appendChild(f);d.setContent(b);this.popupManager.addPopup(d.content)};
WebDataRocksToolbar.prototype.showFormatCellsDialog=function(){var a=this,b=this.Labels,d=function(){n.disabled=h.disabled=w.disabled=p.disabled=q.disabled=r.disabled=s.disabled=t.disabled="empty"==e.value;var b=a.pivot.getFormat(e.value);n.value="left"==b.textAlign||"right"==b.textAlign?b.textAlign:"right";h.value=b.thousandsSeparator;w.value=b.decimalSeparator;p.value=b.decimalPlaces;q.value=b.currencySymbol;r.value=b.currencySymbolAlign;s.value=b.nullValue;t.value=!0==b.isPercent?!0:!1},f=this.popupManager.createPopup();
f.content.id="wdr-popup-format-cells";f.setTitle(this.osUtils.isMobile?b.format:b.format_cells);f.setToolbar([{id:"wdr-btn-apply",label:b.apply,handler:function(){var b={};""==e.value&&(b.name="");b.textAlign=n.value;b.thousandsSeparator=h.value;b.decimalSeparator=w.value;b.decimalPlaces=p.value;b.currencySymbol=q.value;b.currencySymbolAlign=r.value;b.nullValue=s.value;b.isPercent="true"==t.value?!0:!1;a.pivot.setFormat(b,""==e.value?null:e.value);a.pivot.refresh()},isPositive:!0},{id:"wdr-btn-cancel",
label:b.cancel}],!0);var m=document.createElement("div"),g=document.createElement("div");g.classList.add("wdr-inp-group");m.appendChild(g);var c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);var k=document.createElement("label");k.classList.add("wdr-uc");a.setText(k,b.choose_value);c.appendChild(k);var l=a.createSelect(),e=l.select;e.onchange=d;e.options[0]=new Option(b.choose_value,"empty");e.options[0].disabled=!0;e.options[1]=
new Option(b.all_values,"");c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);g=document.createElement("div");g.classList.add("wdr-inp-group");m.appendChild(g);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.text_align);c.appendChild(k);var l=a.createSelect(),n=l.select;n.options[0]=new Option(b.align_left,
"left");n.options[1]=new Option(b.align_right,"right");c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.thousand_separator);c.appendChild(k);var l=a.createSelect(),h=l.select;h.options[0]=new Option(b.none,"");h.options[1]=new Option(b.space," ");h.options[2]=new Option(",",",");h.options[3]=new Option(".",".");c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-inp-row");
c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.decimal_separator);c.appendChild(k);var l=a.createSelect(),w=l.select;w.options[0]=new Option(".",".");w.options[1]=new Option(",",",");c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.decimal_places);c.appendChild(k);for(var l=a.createSelect(),p=l.select,k=0;11>k;k++)p.options[k]=
new Option(0===k?b.none:k-1,k-1);c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.currency_symbol);c.appendChild(k);var q=document.createElement("input");q.classList.add("wdr-inp");q.type="text";c.appendChild(q);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,
b.currency_align);c.appendChild(k);var l=a.createSelect(),r=l.select;r.options[0]=new Option(b.align_left,"left");r.options[1]=new Option(b.align_right,"right");c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-inp-row");c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.null_value);c.appendChild(k);var s=document.createElement("input");s.classList.add("wdr-inp");s.type="text";c.appendChild(s);c=document.createElement("div");c.classList.add("wdr-inp-row");
c.classList.add("wdr-ir-horizontal");g.appendChild(c);k=document.createElement("label");a.setText(k,b.is_percent);c.appendChild(k);var l=a.createSelect(),t=l.select;t.options[0]=new Option(b.true_value,!0);t.options[1]=new Option(b.false_value,!1);c.appendChild(l);f.setContent(m);this.popupManager.addPopup(f.content);b=a.pivot.getMeasures();for(k=0;k<b.length;k++)e.options[k+2]=new Option(b[k].caption,b[k].uniqueName);d()};
WebDataRocksToolbar.prototype.showConditionalFormattingDialog=function(){var a=this,b=this.Labels,d=this.pivot.getAllConditions(),f=function(a){for(var b=a.match(/\W\d+\.*\d*/g),c=[],d=!1,f="",g=0;g<a.length;g++)'"'==a[g]||"'"==a[g]?(d?c.push(f):f="",d=!d):d&&(f+=a[g]);a=a.match(/<=|>=|<|>|=|=|!=|isNaN/g);return null==b&&null==c?{}:{value1:null!=b?b[0].replace(/\s/,""):c[0],value2:null!=b&&1<b.length?b[1].replace(/\s/,""):"",sign:a?a.join(""):""}},m=this.popupManager.createPopup();m.content.id="wdr-popup-conditional";
m.setTitle(this.osUtils.isMobile?b.conditional:b.conditional_formatting);m.setToolbar([{id:"wdr-btn-apply",label:b.apply,handler:function(){a.pivot.removeAllConditions();for(var b=0;b<d.length;b++){var c;c=d[b].sign;var e=d[b].value1,f=d[b].value2,g="",m=null==f||0==f.length,p="><"===c,q="isNaN"===c;(null==e||0==e.length)&&!q||p&&m||(p&&!m?g="AND(#value > "+e+", #value < "+f+")":q?g="isNaN(#value)":(isNaN(parseFloat(e))&&(e="'"+e+"'"),g="#value "+c+" "+e));c=g;if(null==c)return;d[b].formula=c;a.pivot.addCondition(d[b])}a.pivot.refresh()},
isPositive:!0},{id:"wdr-btn-cancel",label:b.cancel}],!0);var g=document.createElement("a");g.id="wdr-add-btn";g.setAttribute("href","javascript:void(0)");g.classList.add("wdr-ui-btn");g.classList.add("wdr-ui-btn-light");g.classList.add("wdr-button-add");g.onclick=function(){var b={sign:"<",value1:"0",measures:a.pivot.getMeasures(),format:{fontFamily:"Arial",fontSize:"12px",color:"#000000",backgroundColor:"#FFFFFF"}};d.push(b);c.appendChild(a.createConditionalFormattingItem(b,d));a.popupManager.centerPopup(m.content)};
g.setAttribute("title",b.add_condition);b=document.createElement("span");b.classList.add("wdr-icon");b.classList.add("wdr-icon-act_add");g.appendChild(b);m.toolbar.insertBefore(g,m.toolbar.firstChild);var c=document.createElement("div");c.classList.add("wdr-popup-content");c.onclick=function(b){b.target.classList.contains("wdr-cr-delete")&&a.popupManager.centerPopup(m.content)};for(g=0;g<d.length;g++)b=f(d[g].formula),d[g].value1=b.value1,d[g].value2=b.value2,d[g].sign=b.sign,d[g].measures=a.pivot.getMeasures(),
c.appendChild(a.createConditionalFormattingItem(d[g],d));m.setContent(c);this.popupManager.addPopup(m.content)};WebDataRocksToolbar.prototype.defaults.fontSizes="8px 9px 10px 11px 12px 13px 14px".split(" ");WebDataRocksToolbar.prototype.defaults.fonts="Arial;Lucida Sans Unicode;Verdana;Courier New;Palatino Linotype;Tahoma;Impact;Trebuchet MS;Georgia;Times New Roman".split(";");
WebDataRocksToolbar.prototype.defaults.conditions=[{label:"less_than",sign:"<"},{label:"less_than_or_equal",sign:"<="},{label:"greater_than",sign:">"},{label:"greater_than_or_equal",sign:">="},{label:"equal_to",sign:"="},{label:"not_equal_to",sign:"!="},{label:"between",sign:"><"},{label:"is_empty",sign:"isNaN"}];
WebDataRocksToolbar.prototype.createConditionalFormattingItem=function(a,b){var d=this,f=this.Labels,m=function(a,b){h[0]=new Option(f.all_values,"");for(var c=d.pivot.getOptions()||{},c=c.grid&&"flat"==c.grid.type,e=0;e<a.length;e++)c&&7==a[e].type||(h[h.options.length]=new Option(a[e].caption,a[e].uniqueName));null!=b?h.value=b:h.selectedIndex=0},g=function(){"sign"in a&&"><"===a.sign?(p.classList.remove("wdr-width120"),p.classList.add("wdr-width50"),p.style.display="inline-block",r.value="value2"in
a?a.value2:"0",r.style.display="inline-block",q.style.display="inline-block"):("sign"in a&&"isNaN"===a.sign?p.style.display="none":(p.classList.add("wdr-width120"),p.classList.remove("wdr-width50"),p.style.display="inline-block"),r.style.display="none",q.style.display="none")},c=function(){var b=a.format;null!=b&&(v.style.backgroundColor=b.backgroundColor||"#fff",v.style.color=b.color||"#000",v.style.fontFamily=b.fontFamily||"Arial",v.style.fontSize=b.fontSize||"12px")},k=document.createElement("div");
k.classList.add("wdr-condition-row");var l=document.createElement("div");l.classList.add("wdr-wrap-relative");k.appendChild(l);var e=document.createElement("span");e.classList.add("wdr-cr-delete");e.classList.add("wdr-icon");e.classList.add("wdr-icon-act_trash");e.onclick=function(){var c=b.indexOf(a);-1<c&&b.splice(c,1);k.parentNode.removeChild(k)};l.appendChild(e);e=document.createElement("div");e.classList.add("wdr-cr-inner");l.appendChild(e);var n=document.createElement("div");n.classList.add("wdr-cr-lbl");
n.classList.add("wdr-width50");d.setText(n,f.value+":");e.appendChild(n);n=d.createSelect();n.id="wdr-values";var h=n.select;"measures"in a?(m(a.measures,a.measure),h.disabled=0===a.measures.length):h.disabled=!0;h.onchange=function(){a.measure=h.value};e.appendChild(n);n=d.createSelect();n.id="wdr-conditions";var w=n.select;(function(a){for(var b=0;b<d.defaults.conditions.length;b++)w[b]=new Option(f[d.defaults.conditions[b].label],d.defaults.conditions[b].sign);null!=a?w.value=a:w.selectedIndex=
0})("sign"in a?a.sign:null);w.onchange=function(){a.sign=w.value;"sign"in a&&"><"===a.sign?a.value2=0:("sign"in a&&"isNaN"===a.sign&&delete a.value1,delete a.value2);g()};e.appendChild(n);var p=document.createElement("input");p.classList.add("wdr-number-inp");p.classList.add("wdr-width50");p.type="number";p.value="value1"in a?a.value1:"0";p.onchange=function(){a.value1=0==p.value.length?"0":p.value};e.appendChild(p);var q=document.createElement("span");q.id="wdr-and-label";q.classList.add("wdr-width20");
d.setText(q,f.and_symbole);e.appendChild(q);var r=document.createElement("input");r.classList.add("wdr-number-inp");r.classList.add("wdr-width50");r.type="number";r.value="value2"in a?a.value2:"0";r.onchange=function(){a.value2=0==r.value.length?"0":r.value};e.appendChild(r);g();e=document.createElement("div");e.classList.add("wdr-cr-inner");l.appendChild(e);n=document.createElement("div");n.classList.add("wdr-cr-lbl");n.classList.add("wdr-width50");d.setText(n,f.format+":");e.appendChild(n);n=d.createSelect();
n.id="wdr-font-family";var s=n.select;(function(a){for(var b=0;b<d.defaults.fonts.length;b++)s[b]=new Option(d.defaults.fonts[b],d.defaults.fonts[b]);s.value=null==a?"Arial":a})(a.hasOwnProperty("format")&&a.format.hasOwnProperty("fontFamily")?a.format.fontFamily:null);s.onchange=function(){null!=a.format&&(a.format.fontFamily=s.value,c())};e.appendChild(n);n=d.createSelect();n.id="wdr-font-size";var t=n.select;(function(a){for(var b=0;b<d.defaults.fontSizes.length;b++)t[b]=new Option(d.defaults.fontSizes[b],
d.defaults.fontSizes[b]);t.value=null==a?"12px":a})(a.hasOwnProperty("format")&&a.format.hasOwnProperty("fontSize")?a.format.fontSize:null);t.onchange=function(){null!=a.format&&(a.format.fontSize=t.value,c())};e.appendChild(n);var u=new WebDataRocksToolbar.ColorPicker(this,k);u.setColor(a.hasOwnProperty("format")&&a.format.hasOwnProperty("backgroundColor")?a.format.backgroundColor:"0xFFFFFF","bg");u.setColor(a.hasOwnProperty("format")&&a.format.hasOwnProperty("color")?a.format.color:"0x000000","font");
u.changeHandler=function(){null!=a.format&&(v.style.color=u.fontColor||"#000",v.style.backgroundColor=u.backgroundColor||"#fff")};u.applyHandler=function(){null!=a.format&&(a.format.color=u.fontColor,a.format.backgroundColor=u.backgroundColor,c())};u.cancelHandler=function(){null!=a.format&&(u.setColor(a.format.hasOwnProperty("backgroundColor")?a.format.backgroundColor:"0xFFFFFF","bg"),u.setColor(a.format.hasOwnProperty("color")?a.format.color:"0x000000","font"));c()};e.appendChild(u.element);var v=
document.createElement("input");v.id="wdr-sample";v.classList.add("wdr-inp");v.type="number";v.value="73.93";v.style.pointerEvents="none";e.appendChild(v);c();return k};
WebDataRocksToolbar.prototype.showOptionsDialog=function(){var a=this,b=this.Labels,d=this.popupManager.createPopup();d.content.id="wdr-popup-options";d.setTitle(this.osUtils.isMobile?b.options:b.layout_options);d.setToolbar([{id:"wdr-btn-apply",label:b.apply,handler:function(){var b;n.checked?b="off":w.checked?b="on":p.checked?b="rows":q.checked&&(b="columns");var c;u.checked?c="off":v.checked?c="on":x.checked?c="rows":y.checked&&(c="columns");var d="compact";s&&s.checked?d="classic":t&&t.checked&&
(d="flat");var e=a.pivot.getOptions(),e={grid:{showGrandTotals:b,showTotals:c,type:d}};a.pivot.setOptions(e);a.pivot.refresh()},isPositive:!0},{id:"wdr-btn-cancel",label:b.cancel}],!0);var f=document.createElement("div");f.classList.add("wdr-popup-content");var m=document.createElement("div");m.classList.add("wdr-ui-row");f.appendChild(m);var g=document.createElement("div");g.classList.add("wdr-ui-col-2");m.appendChild(g);var c=document.createElement("div");c.classList.add("wdr-title-2");a.setText(c,
b.grand_totals);g.appendChild(c);var k="wdr-grand-totals-"+Date.now(),c=document.createElement("ul");c.classList.add("wdr-radiobtn-list");g.appendChild(c);var l=document.createElement("li"),e=document.createElement("div");e.classList.add("wdr-radio-wrap");var n=document.createElement("input");n.type="radio";n.name=k;n.id="wdr-gt-1";n.value="off";e.appendChild(n);var h=document.createElement("label");h.setAttribute("for","wdr-gt-1");a.setText(h,b.grand_totals_off);e.appendChild(h);l.appendChild(e);
c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var w=document.createElement("input");w.type="radio";w.name=k;w.id="wdr-gt-2";w.value="on";e.appendChild(w);h=document.createElement("label");h.setAttribute("for","wdr-gt-2");a.setText(h,b.grand_totals_on);e.appendChild(h);l.appendChild(e);c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var p=document.createElement("input");
p.type="radio";p.name=k;p.id="wdr-gt-3";p.value="rows";e.appendChild(p);h=document.createElement("label");h.setAttribute("for","wdr-gt-3");a.setText(h,b.grand_totals_on_rows);e.appendChild(h);l.appendChild(e);c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var q=document.createElement("input");q.type="radio";q.name=k;q.id="wdr-gt-4";q.value="rows";e.appendChild(q);h=document.createElement("label");h.setAttribute("for","wdr-gt-4");a.setText(h,
b.grand_totals_on_columns);e.appendChild(h);l.appendChild(e);c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-title-2");a.setText(c,b.layout);g.appendChild(c);var r="wdr-layout-"+Date.now(),c=document.createElement("ul");c.classList.add("wdr-radiobtn-list");g.appendChild(c);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");k=document.createElement("input");k.type="radio";k.name=r;k.id="wdr-lt-1";k.value="compact";e.appendChild(k);
h=document.createElement("label");h.setAttribute("for","wdr-lt-1");a.setText(h,b.compact_view);e.appendChild(h);l.appendChild(e);c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var s=document.createElement("input");s.type="radio";s.name=r;s.id="wdr-lt-2";s.value="classic";e.appendChild(s);h=document.createElement("label");h.setAttribute("for","wdr-lt-2");a.setText(h,b.classic_view);e.appendChild(h);l.appendChild(e);c.appendChild(l);
g=a.pivot.getReport({withDefaults:!0,withGlobals:!0});l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var t=document.createElement("input");t.type="radio";t.name=r;t.id="wdr-lt-3";t.value="flat";e.appendChild(t);h=document.createElement("label");h.setAttribute("for","wdr-lt-3");a.setText(h,b.flat_view);e.appendChild(h);l.appendChild(e);c.appendChild(l);g=document.createElement("div");g.classList.add("wdr-ui-col-2");m.appendChild(g);c=document.createElement("div");
c.classList.add("wdr-title-2");a.setText(c,b.subtotals);g.appendChild(c);m="wdr-subtotals-"+Date.now();c=document.createElement("ul");c.classList.add("wdr-radiobtn-list");g.appendChild(c);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var u=document.createElement("input");u.type="radio";u.name=m;u.id="wdr-st-1";u.value="off";e.appendChild(u);h=document.createElement("label");h.setAttribute("for","wdr-st-1");a.setText(h,b.subtotals_off);e.appendChild(h);
l.appendChild(e);c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var v=document.createElement("input");v.type="radio";v.name=m;v.id="wdr-st-2";v.value="on";e.appendChild(v);h=document.createElement("label");h.setAttribute("for","wdr-st-2");a.setText(h,b.subtotals_on);e.appendChild(h);l.appendChild(e);c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var x=document.createElement("input");
x.type="radio";x.name=m;x.id="wdr-st-3";x.value="rows";e.appendChild(x);h=document.createElement("label");h.setAttribute("for","wdr-st-3");a.setText(h,b.subtotals_on_rows);e.appendChild(h);l.appendChild(e);c.appendChild(l);l=document.createElement("li");e=document.createElement("div");e.classList.add("wdr-radio-wrap");var y=document.createElement("input");y.type="radio";y.name=m;y.id="wdr-st-4";y.value="columns";e.appendChild(y);h=document.createElement("label");h.setAttribute("for","wdr-st-4");a.setText(h,
b.subtotals_on_columns);e.appendChild(h);l.appendChild(e);c.appendChild(l);d.setContent(f);this.popupManager.addPopup(d.content);g=a.pivot.getOptions()||{};b=g.grid||{};"off"==b.showGrandTotals||!1==b.showGrandTotals?n.checked=!0:"on"==b.showGrandTotals||!0==b.showGrandTotals?w.checked=!0:"rows"==b.showGrandTotals?p.checked=!0:"columns"==b.showGrandTotals&&(q.checked=!0);"off"==b.showTotals?u.checked=!0:"on"==b.showTotals?v.checked=!0:"rows"==b.showTotals?x.checked=!0:"columns"==b.showTotals&&(y.checked=
!0);"flat"==b.type&&t?t.checked=!0:"classic"==b.type&&s?s.checked=!0:k&&(k.checked=!0)};
WebDataRocksToolbar.prototype.showExportPdfDialog=function(){var a=this,b=this.Labels,d=this.popupManager.createPopup();d.setTitle(b.choose_page_orientation);d.setToolbar([{id:"wdr-btn-apply",label:b.apply,handler:function(){var b="portrait";k.checked&&(b="landscape");a.pivot.exportTo("pdf",{pageOrientation:b})},isPositive:!0},{id:"wdr-btn-cancel",label:b.cancel}]);var f=document.createElement("div"),m=document.createElement("ul");m.classList.add("wdr-radiobtn-list");f.appendChild(m);var g=document.createElement("li");
m.appendChild(g);var c=document.createElement("div");c.classList.add("wdr-radio-wrap");g.appendChild(c);g=document.createElement("input");g.id="wdr-portrait-radio";g.type="radio";g.name="wdr-pdf-orientation";g.checked=!0;c.appendChild(g);g=document.createElement("label");g.setAttribute("for","wdr-portrait-radio");a.setText(g,b.portrait);c.appendChild(g);g=document.createElement("li");m.appendChild(g);c=document.createElement("div");c.classList.add("wdr-radio-wrap");g.appendChild(c);var k=document.createElement("input");
k.id="wdr-landscape-radio";k.type="radio";k.name="wdr-pdf-orientation";c.appendChild(k);g=document.createElement("label");g.setAttribute("for","wdr-landscape-radio");a.setText(g,b.landscape);c.appendChild(g);d.setContent(f);this.popupManager.addPopup(d.content)};WebDataRocksToolbar.prototype.toggleFullscreen=function(){this.isFullscreen()?this.exitFullscreen():this.enterFullscreen(this.container)};
WebDataRocksToolbar.prototype.isFullscreen=function(){return document.fullScreenElement||document.mozFullScreenElement||document.webkitFullscreenElement||document.msFullscreenElement};
WebDataRocksToolbar.prototype.enterFullscreen=function(a){if(a.requestFullscreen||a.webkitRequestFullScreen||a.mozRequestFullScreen||a.msRequestFullscreen&&window==top){this.containerStyle={width:this.container.style.width,height:this.container.style.height,position:this.container.style.position,top:this.container.style.top,bottom:this.container.style.bottom,left:this.container.style.left,right:this.container.style.right,marginTop:this.container.style.marginTop,marginLeft:this.container.style.left,
toolbarWidth:this.toolbarWrapper.style.width};this.container.style.width="100%";this.container.style.height="100%";this.container.style.position="fixed";this.container.style.top="0px";this.container.style.left="0px";this.toolbarWrapper.style.width="100%";if(a.requestFullscreen)a.requestFullscreen();else if(a.webkitRequestFullScreen){var b=navigator.userAgent;-1<b.indexOf("Safari")&&-1==b.indexOf("Chrome")?a.webkitRequestFullScreen():a.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)}else a.mozRequestFullScreen?
a.mozRequestFullScreen():a.msRequestFullscreen&&(window==top?a.msRequestFullscreen():alert("Fullscreen mode in IE 11 is not currently supported while Pivot embeded in iframe."));a.addEventListener("fullscreenchange",function(){window.screenTop||window.screenY||this.isFullscreen()||this.exitFullscreen()}.bind(this),!1);a.addEventListener("webkitfullscreenchange",function(){window.screenTop||window.screenY||this.isFullscreen()||this.exitFullscreen()}.bind(this),!1);a.addEventListener("mozfullscreenchange",
function(){window.fullScreen||window.innerWidth==screen.width&&window.innerHeight==screen.height||this.exitFullscreen()}.bind(this),!1)}};
WebDataRocksToolbar.prototype.exitFullscreen=function(){this.container.style.width=this.containerStyle.width;this.container.style.height=this.containerStyle.height;this.container.style.position=this.containerStyle.position;this.container.style.top=this.containerStyle.top;this.container.style.left=this.containerStyle.left;this.container.style.marginTop=this.containerStyle.marginTop;this.container.style.marginLeft=this.containerStyle.marginLeft;this.toolbarWrapper.style.width=this.containerStyle.toolbarWidth;
document.exitFullscreen?document.exitFullscreen():document.cancelFullscreen?document.cancelFullscreen():document.mozCancelFullScreen?document.mozCancelFullScreen():document.webkitExitFullScreen?document.webkitExitFullScreen():document.webkitCancelFullScreen?document.webkitCancelFullScreen():document.msExitFullscreen&&document.msExitFullscreen()};WebDataRocksToolbar.prototype.nullOrUndefined=function(a){return"undefined"===typeof a||null===a};
WebDataRocksToolbar.prototype.hasClass=function(a,b){return a.className.match(RegExp("(\\s|^)"+b+"(\\s|$)"))};WebDataRocksToolbar.prototype.addClass=function(a,b){this.hasClass(a,b)||(a.className+=" "+b)};WebDataRocksToolbar.prototype.removeClass=function(a,b){this.hasClass(a,b)&&(a.className=a.className.replace(RegExp("(\\s|^)"+b+"(\\s|$)")," "))};WebDataRocksToolbar.prototype.setText=function(a,b){a&&(void 0!==a.innerText&&(a.innerText=b),void 0!==a.textContent&&(a.textContent=b))};
WebDataRocksToolbar.prototype.createSelect=function(){var a=document.createElement("div");a.classList.add("wdr-select");var b=document.createElement("select");a.appendChild(b);a.select=b;return a};WebDataRocksToolbar.prototype.createDivider=function(a){a=document.createElement("li");a.className="wdr-divider";return a};
WebDataRocksToolbar.prototype.createTab=function(a){var b=document.createElement("li");b.id=a.id;var d=document.createElement("a");a.hasOwnProperty("class_attr")&&d.setAttribute("class",a.class_attr);d.setAttribute("href","javascript:void(0)");if(a.icon){var f=document.createElement("div");f.classList.add("wdr-svg-icon");f.innerHTML=a.icon;d.appendChild(f)}f=document.createElement("span");this.setText(f,a.title);d.appendChild(f);var m=this,f="function"==typeof a.handler?a.handler:this[a.handler];
this.nullOrUndefined(f)||(d.onclick=function(a,b){return function(){a.call(m,b)}}(f,a.args));this.nullOrUndefined(this[a.onShowHandler])||(d.onmouseover=function(a){return function(){a.call(m)}}(this[a.onShowHandler]));b.onmouseover=function(){m.showDropdown(this)};b.onmouseout=function(){m.hideDropdown(this)};b.appendChild(d);null==a.menu||this.osUtils.isMobile&&!0!=a.collapse||b.appendChild(this.createTabMenu(a.menu));return b};
WebDataRocksToolbar.prototype.showDropdown=function(a){var b=a.querySelectorAll(".wdr-dropdown")[0];b&&(b.style.display="block",b.getBoundingClientRect().right>this.toolbarWrapper.getBoundingClientRect().right&&(b.style.right=0,this.addClass(a,"wdr-align-rigth")))};WebDataRocksToolbar.prototype.hideDropdown=function(a){var b=a.querySelectorAll(".wdr-dropdown")[0];b&&(b.style.display="none",b.style.right=null,this.removeClass(a,"wdr-align-rigth"))};
WebDataRocksToolbar.prototype.createTabMenu=function(a){var b=document.createElement("div");b.className="wdr-dropdown wdr-shadow-container";var d=document.createElement("ul");d.className="wdr-dropdown-content";for(var f=0;f<a.length;f++)this.isDisabled(a[f])||d.appendChild(a[f].divider?this.createMenuDivider():this.createTab(a[f]));b.appendChild(d);return b};WebDataRocksToolbar.prototype.createMenuDivider=function(){var a=document.createElement("li");a.className="wdr-v-divider";return a};
WebDataRocksToolbar.prototype.isDisabled=function(a){return this.nullOrUndefined(a)?!0:!1===a.ios&&this.osUtils.isIOS||!1===a.android&&this.osUtils.isAndroid||!1===a.mobile&&this.osUtils.isMobile};WebDataRocksToolbar.prototype.getElementById=function(a,b){var d=function(a,b){for(var g=0;g<a.childNodes.length;g++){var c=a.childNodes[g];if(c.id==b)return c;c=d(c,b);if(null!=c)return c}return null};return d(b||this.toolbarWrapper,a)};
WebDataRocksToolbar.prototype.osUtils={isIOS:navigator.userAgent.match(/iPhone|iPad|iPod/i)||navigator.platform.match(/iPhone|iPad|iPod/i)?!0:!1,isMac:/Mac/i.test(navigator.platform),isAndroid:navigator.userAgent.match(/Android/i)?!0:!1,isBlackBerry:/BlackBerry/i.test(navigator.platform),isMobile:navigator.userAgent.match(/iPhone|iPad|iPod/i)||navigator.platform.match(/iPhone|iPad|iPod/i)||navigator.userAgent.match(/Android/i)||/BlackBerry/i.test(navigator.platform)};
WebDataRocksToolbar.PopupManager=function(a){this.toolbar=a;this.activePopup=null};WebDataRocksToolbar.PopupManager.prototype.createPopup=function(){return new WebDataRocksToolbar.PopupManager.PopupWindow(this)};
WebDataRocksToolbar.PopupManager.prototype.addPopup=function(a){if(null!=a){this.removePopup();this.modalOverlay=this.createModalOverlay();this.activePopup=a;this.toolbar.toolbarWrapper.appendChild(a);this.toolbar.toolbarWrapper.appendChild(this.modalOverlay);this.addLayoutClasses(a);this.centerPopup(a);var b=this;a.resizeHandler=function(){a&&(b.addLayoutClasses(a),b.centerPopup(a))};window.addEventListener("resize",a.resizeHandler)}};
WebDataRocksToolbar.PopupManager.prototype.addLayoutClasses=function(a){a.classList.remove("wdr-layout-tablet");a.classList.remove("wdr-layout-mobile");a.classList.remove("wdr-layout-mobile-small");var b=this.getBoundingRect(this.toolbar.container);768>b.width&&a.classList.add("wdr-layout-tablet");580>b.width&&a.classList.add("wdr-layout-mobile");460>b.width&&a.classList.add("wdr-layout-mobile-small")};
WebDataRocksToolbar.PopupManager.prototype.centerPopup=function(a){var b=this.getBoundingRect(this.toolbar.container),d=this.getBoundingRect(a),f=this.getBoundingRect(this.toolbar.toolbarWrapper);a.style.zIndex=parseInt(this.modalOverlay.style.zIndex)+1;this.modalOverlay.style.height=b.height+"px";a.style.left=Math.max(0,(f.width-d.width)/2)+"px";a.style.top=Math.max(0,(b.height-d.height)/2)+"px"};
WebDataRocksToolbar.PopupManager.prototype.removePopup=function(a){a=a||this.activePopup;null!=this.modalOverlay&&(this.toolbar.toolbarWrapper.removeChild(this.modalOverlay),this.modalOverlay=null);null!=a&&(this.toolbar.toolbarWrapper.removeChild(a),this.activePopup=null,window.removeEventListener("resize",a.resizeHandler))};
WebDataRocksToolbar.PopupManager.prototype.getBoundingRect=function(a){var b=a.getBoundingClientRect();return{left:b.left,right:b.right,top:b.top,bottom:b.bottom,width:b.width||a.clientWidth,height:b.height||a.clientHeight}};WebDataRocksToolbar.PopupManager.prototype.createModalOverlay=function(){var a=document.createElement("div");a.className="wdr-modal-overlay";a.id="wdr-popUp-modal-overlay";var b=this;a.addEventListener("click",function(a){b.removePopup(b.activePopup)});return a};
WebDataRocksToolbar.PopupManager.PopupWindow=function(a){this.popupManager=a;var b=document.createElement("div");b.className="wdr-panel-content";var d=document.createElement("div");d.className="wdr-title-bar";var f=document.createElement("div");f.className="wdr-title-text";var m=document.createElement("div");m.className="wdr-toolbox";m.style.clear="both";this.content=document.createElement("div");this.content.className="wdr-popup wdr-panel wdr-toolbar-ui wdr-ui";this.content.appendChild(b);b.appendChild(d);
d.appendChild(f);this.setTitle=function(a){WebDataRocksToolbar.prototype.setText(f,a)};this.setContent=function(a){b.insertBefore(a,d.nextSibling)};var g=this;this.setToolbar=function(a,k){m.innerHTML="";for(var l=a.length-1;0<=l;l--){var e=document.createElement("a");e.setAttribute("href","javascript:void(0)");e.className="wdr-ui-btn"+(a[l].isPositive?" wdr-ui-btn-dark":"");a[l].id&&(e.id=a[l].id);WebDataRocksToolbar.prototype.setText(e,a[l].label);e.onclick=function(a){return function(){null!=a&&
a.call();g.popupManager.removePopup()}}(a[l].handler);!0===a[l].disabled?WebDataRocksToolbar.prototype.addClass(e,"wdr-ui-disabled"):WebDataRocksToolbar.prototype.removeClass(e,"wdr-ui-disabled");a[l].isPositive&&(WebDataRocksToolbar.prototype.osUtils.isMac||WebDataRocksToolbar.prototype.osUtils.isIOS)?m.appendChild(e):m.insertBefore(e,m.firstChild)}k?(m.classList.add("wdr-ui-col"),d.appendChild(m),d.classList.add("wdr-ui-row"),f.classList.add("wdr-ui-col")):b.appendChild(m)};this.toolbar=m;this.titleBar=
d;this.title=f;return this};
WebDataRocksToolbar.ColorPicker=function(a,b){function d(a){a=a.target.getAttribute("data-c");h.drawShades(h.colors[a]);h.setColor(a,h.currentType,!0)}function f(a){a=a.target.getAttribute("data-c");h.setColor(a,h.currentType,!0)}function m(a){h.currentType=a;k.classList.remove("wdr-current");l.classList.remove("wdr-current");"bg"==a?(l.classList.add("wdr-current"),h.setColor(h.backgroundColor,a,!1)):(k.classList.add("wdr-current"),h.setColor(h.fontColor,a,!1))}function g(){h.closePopup();h.cancelHandler&&
h.cancelHandler()}this.toolbar=a;this.element=document.createElement("div");this.element.classList.add("wdr-colorpick-wrap");this.element.classList.add("wdr-width40");this.colorPickerButton=document.createElement("div");this.colorPickerButton.classList.add("wdr-colorpick-btn");this.element.appendChild(this.colorPickerButton);this.colorPickerIcon=document.createElement("span");this.colorPickerIcon.classList.add("wdr-icon");this.colorPickerIcon.classList.add("wdr-icon-act_font");this.colorPickerButton.appendChild(this.colorPickerIcon);
this.popup=document.createElement("div");this.popup.classList.add("wdr-colorpick-popup");this.popup.onclick=function(a){a.stopPropagation()};b.appendChild(this.popup);var c=document.createElement("div");c.classList.add("wdr-color-targ-switch");this.popup.appendChild(c);var k=document.createElement("a");k.classList.add("wdr-cts-item");k.classList.add("wdr-current");k.href="javascript:void(0);";k.innerHTML=a.Labels.cp_text;k.onclick=function(){m("font")};c.appendChild(k);var l=document.createElement("a");
l.classList.add("wdr-cts-item");l.innerHTML=a.Labels.cp_highlight;l.href="javascript:void(0);";l.onclick=function(){m("bg")};c.appendChild(l);c=document.createElement("div");c.classList.add("wdr-cp-sett-row");this.popup.appendChild(c);this.colorInput=document.createElement("input");this.colorInput.type="text";this.colorInput.classList.add("wdr-inp");this.colorInput.classList.add("wdr-width140");this.colorInput.classList.add("wdr-tac");this.colorInput.onchange=function(){var a=h.colorInput.value;h.isColor(a)&&
h.setColor(a,h.currentType,!0)};c.appendChild(this.colorInput);this.colorPreview=document.createElement("div");this.colorPreview.classList.add("wdr-cp-curr-color");this.colorPreview.classList.add("wdr-width140");c.appendChild(this.colorPreview);this.mainColors=document.createElement("div");this.mainColors.classList.add("wdr-row-9colors");this.popup.appendChild(this.mainColors);for(var e in this.colors){c=document.createElement("div");c.classList.add("wdr-r9c-item");c.style.backgroundColor=e;c.setAttribute("data-c",
e);c.addEventListener("click",d);this.mainColors.appendChild(c);var n=document.createElement("span");n.classList.add("wdr-cp-currentmark");n.classList.add("wdr-icon");n.classList.add("wdr-icon-act_check");c.appendChild(n);n=document.createElement("span");n.classList.add("wdr-r9c-arrow");n.style.borderTopColor=e;c.appendChild(n)}this.shadeColors=document.createElement("div");this.shadeColors.classList.add("wdr-row-4colors");this.popup.appendChild(this.shadeColors);for(e=0;8>e;e++)c=document.createElement("div"),
c.classList.add("wdr-r4c-item"),c.addEventListener("click",f),this.shadeColors.appendChild(c),n=document.createElement("span"),n.classList.add("wdr-cp-currentmark"),n.classList.add("wdr-icon"),n.classList.add("wdr-icon-act_check"),c.appendChild(n);this.drawShades(this.colors["#000000"]);c=document.createElement("div");c.classList.add("wdr-cp-btns-row");this.popup.appendChild(c);e=document.createElement("a");e.innerHTML=a.Labels.apply;e.classList.add("wdr-ui-btn");e.classList.add("wdr-ui-btn-dark");
e.addEventListener("click",function(){h.closePopup();h.applyHandler&&h.applyHandler()});n=document.createElement("a");n.innerHTML=a.Labels.cancel;n.classList.add("wdr-ui-btn");n.addEventListener("click",g);WebDataRocksToolbar.prototype.osUtils.isMac||WebDataRocksToolbar.prototype.osUtils.isIOS?(c.appendChild(n),c.appendChild(e)):(c.appendChild(e),c.appendChild(n));this.currentType="font";this.colorPickerButton.addEventListener("click",function(a){a.stopPropagation();h.isOpened()?h.closePopup():h.openPopup()});
document.body.addEventListener("click",function(a){g()});var h=this};
WebDataRocksToolbar.ColorPicker.prototype.colors={"#000000":"#000000 #212121 #424242 #616161 #757575 #9E9E9E #BDBDBD #FFFFFF".split(" "),"#F44336":"#D32F2F #E53935 #F44336 #EF5350 #E57373 #EF9A9A #FFCDD2 #FFEBEE".split(" "),"#FF9800":"#F57C00 #FB8C00 #FF9800 #FFA726 #FFB74D #FFCC80 #FFE0B2 #FFF3E0".split(" "),"#FFEB3B":"#FBC02D #FDD835 #FFEB3B #FFEE58 #FFF176 #FFF59D #FFF9C4 #FFFDE7".split(" "),"#8BC34A":"#689F38 #7CB342 #8BC34A #9CCC65 #AED581 #C5E1A5 #DCEDC8 #F1F8E9".split(" "),"#009688":"#00796B #00897B #009688 #26A69A #4DB6AC #80CBC4 #B2DFDB #E0F2F1".split(" "),
"#03A9F4":"#0288D1 #039BE5 #03A9F4 #29B6F6 #4FC3F7 #81D4FA #B3E5FC #E1F5FE".split(" "),"#3F51B5":"#303F9F #3949AB #3F51B5 #5C6BC0 #7986CB #9FA8DA #C5CAE9 #E8EAF6".split(" "),"#9C27B0":"#7B1FA2 #8E24AA #9C27B0 #AB47BC #BA68C8 #CE93D8 #E1BEE7 #F3E5F5".split(" ")};WebDataRocksToolbar.ColorPicker.prototype.isOpened=function(){return this.popup.parentElement&&this.popup.parentElement.classList.contains("wdr-popup-opened")};
WebDataRocksToolbar.ColorPicker.prototype.drawShades=function(a){if(a)for(var b=this.shadeColors.children,d=0;d<b.length;d++){var f=b[d];f.setAttribute("data-c",a[d]);f.style.backgroundColor=a[d];f.style.borderRight="#FFFFFF"==a[d]?"1px solid #d5d5d5":"none";f.style.borderBottom="#FFFFFF"==a[d]?"1px solid #d5d5d5":"none"}};
WebDataRocksToolbar.ColorPicker.prototype.setColor=function(a,b,d){"string"===typeof a&&0==a.indexOf("0x")&&(a="#"+a.substr(2));"bg"==b?(this.backgroundColor=a,this.colorPickerButton.style.backgroundColor=a):(this.fontColor=a,this.colorPickerIcon.style.color=a);this.colorInput.value=a;this.colorPreview.style.backgroundColor=a;this.drawSelected();d&&this.changeHandler&&this.changeHandler()};
WebDataRocksToolbar.ColorPicker.prototype.drawSelected=function(){var a="bg"==this.currentType?this.backgroundColor:this.fontColor,b=this.findMain(a);this.drawShades(this.colors[b]);for(var d=this.mainColors.children,f=0;f<d.length;f++)d[f].classList.remove("wdr-current");(b=this.mainColors.querySelector("[data-c='"+b+"']"))&&b.classList.add("wdr-current");d=this.shadeColors.children;for(f=0;f<d.length;f++)d[f].classList.remove("wdr-current");(a=this.shadeColors.querySelector("[data-c='"+a+"']"))&&
a.classList.add("wdr-current")};WebDataRocksToolbar.ColorPicker.prototype.findMain=function(a){"string"===typeof a&&0==a.indexOf("0x")&&(a="#"+a.substr(2));for(var b in this.colors)if(0<=this.colors[b].indexOf(a))return b};WebDataRocksToolbar.ColorPicker.prototype.isColor=function(a){return a.match(/^#?[0-9A-Fa-f]{6}$/g)};WebDataRocksToolbar.ColorPicker.prototype.closePopup=function(){this.popup.parentElement&&this.popup.parentElement.classList.remove("wdr-popup-opened")};
WebDataRocksToolbar.ColorPicker.prototype.openPopup=function(){for(var a=this.toolbar.toolbarWrapper.querySelectorAll(".wdr-colorpick-popup"),b=0;b<a.length;b++)a[b].parentElement.classList.remove("wdr-popup-opened");this.popup.parentElement&&(a=this.toolbar.toolbarWrapper.querySelector("#wdr-popup-conditional .wdr-panel-content"),b=this.getWhere(this.colorPickerButton,a),0>this.getWhere(this.colorPickerButton,document.body).top-this.popup.clientHeight?(this.popup.classList.remove("wdr-arrow-down"),
this.popup.classList.add("wdr-arrow-up"),this.popup.style.top=this.colorPickerButton.clientHeight+b.top+11+"px",this.popup.style.bottom=""):(this.popup.classList.add("wdr-arrow-down"),this.popup.classList.remove("wdr-arrow-up"),this.popup.style.bottom=a.clientHeight-b.top+5+"px",this.popup.style.top=""),this.popup.style.left=b.left+this.colorPickerButton.clientWidth/2+2+"px",this.popup.parentElement.classList.add("wdr-popup-opened"))};
WebDataRocksToolbar.ColorPicker.prototype.getWhere=function(a,b){var d=0,f=0,m=0,g=0;if(a.offsetParent){for(var d=a.offsetLeft,f=a.offsetTop,c=a;(c=c.parentNode)&&c!=b;)m=c.scrollTop?c.scrollTop:0,g=0,d-=g,f-=m;for(;(a=a.offsetParent)&&a!=b;)d+=a.offsetLeft,f+=a.offsetTop}return{top:f+0,left:d+0}};
